import { RestService } from './../../services/rest.service';
import { UtilService } from '../../services/util.service';
import {IonContent, LoadingController, NavController, Platform} from '@ionic/angular';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import {VariableNavBarService} from '../../services/variable-nav-bar.service';

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'app-search-louderband',
  templateUrl: './search-louderband.component.html',
  styleUrls: ['./search-louderband.component.scss'],
  viewProviders: [IonContent]

})
export class SearchLouderbandComponent implements OnInit, OnChanges {
  @Input() searchTop: any;
  @Input() searchSection: any;
  @Input() searchCategory: any;
  @Input() isMobile: boolean;
  @Input() showOverSearch: boolean;
  @Input() helpFocus: boolean;
  @Input() loadMostWanted: boolean;
  filterSelect: string = 'menor';
  @Output() outData = new EventEmitter<any>();
  @Output() newPost = new EventEmitter<any>();
  @ViewChild('popoverFilter') popoverFilter;
  isOpenFilter = false;
  filter: any = {};
  filter_parameters: any[] = [];
  coursesMostWanted: any[] = [];
  mostWanted: any[] = [];
  lessonsMostWanted: any[] = [];
  servicesMostWanted: any[] = [];
  servicesAll: any[] = [];
  servicesCurrent: any[] = [];
  recentsearch: any[] = [];
  algolia: any;
  algoliaHits: any[] = [];
  featuredElement: any;
  banners: any[];
  appendTeachers: boolean = false;
  map_db_to_page: { Lesson: any; "Sound-Market": any; Course: any; Disc: any; Profile: any; Search: any; };
  map_page_to_db: { Profesores: string; Servicios: string; "Cursos on demand": string; Producciones: string; Usuarios: string; "Búsquedas publicadas": string; "Published searches": string; };
  config3M: SwiperOptions = {
    initialSlide: 1,
    slidesPerView: 1,
    spaceBetween: 60,
    navigation: false,
    centeredSlides: true,
    centeredSlidesBounds: true,
    slideToClickedSlide: true
  };

  @ViewChild('swiper34M', { static: false }) swiper34M: ElementRef;
  offsetcount: number = 0;
  query: any;
  search_section: any;
  isAllData: any;
  modalShowRegisterTelco: boolean;
  modalShowRegister: boolean;

  constructor(
    public navCtrl: NavController,
    public platform: Platform,
    public util: UtilService,
    public rest: RestService,
    public location: Location,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    public loadingCtrl: LoadingController,
    public variableNavBarService: VariableNavBarService
  ) {
    if (this.platform.is('android') || this.platform.is('ios')) {
      this.isMobile = true;
    }

    this.initBanners();
  }

  ngOnInit() {
    this.get_featured_services();
    this.get_most_wanted_course();
    this.get_most_wanted_lesson();
    this.get_most_wanted_service();
    this.get_featured_element();
    this.get_saved_search();
    this.map_db_to_page = { "Lesson": this.translate.instant('SEARCH_TEACHERS'), "Sound-Market": this.translate.instant('SEARCH_SERVICES'), "Course": this.translate.instant('SEARCH_LESSONS_COURSES'), "Disc": this.translate.instant('SEARCH_CREDITS'), "Profile": this.translate.instant('SEARCH_USERS'), "Search": this.translate.instant('SEARCH_PUBLISHED_SEARCHES') }
    this.map_page_to_db = { "Profesores": "Lesson", "Servicios": "Sound-Market", "Cursos on demand": "Course", "Producciones": "Disc", "Usuarios": "Profile", "Búsquedas publicadas": "Search", "Published searches": "Search" };
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    console.log('From Search', 'Search term:', this.searchTop, 'Search section:' + this.searchSection, 'Search category:' + this.searchCategory);
    // tslint:disable-next-line:forin
    for (const propName in changes) {
      if (propName === 'searchTop') {
        const searchTop: SimpleChange = changes.searchTop;
        console.log('prev value: ', searchTop.previousValue);
        console.log('got name: ', searchTop.currentValue);
        this.searchTop = searchTop.currentValue;
        if (this.searchTop) {
          this.algoliaHits = [];
          this.offsetcount = 0;
          this.isAllData = false;
          this.getResults();
          // this.showOverSearch = false;
        }
      } else if (propName === 'showOverSearch') {
        const showOverSearch: SimpleChange = changes.showOverSearch;
        console.log('prev value: ', showOverSearch.previousValue);
        console.log('got name: ', showOverSearch.currentValue);
        this.showOverSearch = showOverSearch.currentValue;
      }
    }

  }

  lefB1(swiper34M) {
    swiper34M.swiperRef.slidePrev();
  }

  rightB1(swiper34M) {
    swiper34M.swiperRef.slideNext();
  }



  get_most_wanted_course() {
    const apiHelp = 'courses/mostWanted';
    this.rest._get(apiHelp).subscribe(async (response) => {
      let courses: any = response.body;
      courses = courses.map(course => ({ ...course, url: location.origin + '/cursos/' + course.id }));
      this.coursesMostWanted = courses.slice(2, 6);
      this.mostWanted.push(courses[0]);
      this.mostWanted.push(courses[1]);
      console.log('cursos mas buscados', this.coursesMostWanted);
    }, (error) => {
      console.error('error: ', error);
    });

  }


  get_most_wanted_lesson() {
    const apiHelp = 'lessons/mostWanted';
    this.rest._get(apiHelp).subscribe(async (response) => {
      let lessons: any = response.body;
      lessons = lessons.map(lesson => ({ ...lesson, cover: lesson.image, owner: { name: lesson.user.username }, url: location.origin + '/servicios/lesson/' + lesson.lesson_id }));
      this.lessonsMostWanted = lessons.slice(1, 5);
      this.mostWanted.push(lessons[0]);
      console.log('lessons mas buscados', this.lessonsMostWanted);
    }, (error) => {
      console.error('error: ', error);
    });

  }

  get_most_wanted_service() {
    const apiHelp = 'services/mostWanted';
    this.rest._get(apiHelp).subscribe(async (response) => {
      let services: any = response.body;
      services = services.map(service => ({ ...service, url: location.origin + '/servicios/sound/' + service.service_id, cover: service.image, owner: { name: service.user.username } }));
      this.servicesMostWanted = services.slice(1, 5);
      this.mostWanted.push(services[0]);
      // this.loadMostWanted = true;
      console.log('services mas buscados', this.servicesMostWanted);
    }, (error) => {
      console.error('error: ', error);
    });

  }


  get_featured_services() {
    var apiHelpServices = 'services/featured-elements';
    this.rest._get(apiHelpServices).subscribe(async (response) => {
      const services: any = response.body;

      this.servicesAll = services.items;
      this.servicesAll = this.servicesAll.map(service => ({ ...service, url: location.origin + '/servicios/sound/' + service.service_id }));
      this.servicesCurrent = this.servicesAll.filter(service => service.user_type_id == 2);
      this.outData.emit({ servicesCurrent: this.servicesCurrent, servicesAll: this.servicesAll });
    }, (error) => {
      console.error('Error loading featured services: ', error);
    });

    var apiHelpLessons = 'lessons/featured-elements';
    this.rest._get(apiHelpLessons).subscribe(async (response) => {
      const lessons: any = response.body;
      var lessonsAll: any = lessons.items.map(lesson => ({ ...lesson, url: location.origin + '/servicios/lesson/' + lesson.element_id }));;
      this.outData.emit({ lessonsAll: lessonsAll });
    }, (error) => {
      console.error('Error loading featured lessons: ', error);
    });
  }

  get_featured_element() {
    const apiHelp = 'featured';
    this.rest._get(apiHelp).subscribe(async (response) => {
      var featured: any = response.body;

      if (featured.type != 'course') {
        featured.items.cover = featured.items.image;
      }

      if (featured.type == 'course') {
        let items = featured.items;
        items = { ...items, username: items ? items.owner.name : '' };
        featured.items = items;
      }

      if (featured.type == 'lesson') {
        let items = featured.items;
        items = { ...items, username: items.user.username };
        featured.items = items;
      }

      if (featured.type == 'service') {
        let items = featured.items;
        items = { ...items, username: items.user.username };
        featured.items = items;
      }

      this.featuredElement = featured;
      console.log('featured', this.featuredElement);

    }, (error) => {
      console.error('error: ', error);
    });
  }

  get_saved_search() {
    const apiHelp = 'recentsearch';
    this.rest._get(apiHelp).subscribe(async (response) => {
      const featured: any = response.body;
      this.recentsearch = featured.data;
      console.log('recentsearch', this.recentsearch);

    }, (error) => {
      console.error('error: ', error);
    });
  }

  initBanners() {
    this.banners = [];
    this.getBanners(['service_lesson_view', 'opportunities_view', 'search_results']);
  }

  getBanners(sections) {

    let apiHelp = 'banners';
    if (!this.util.token) {
      apiHelp += '/guest'
    }

    const default_banner = "assets/imagen/img_bg_top.png";

    sections.forEach(element => {
      this.banners.push({ 'banner_section': element, 'banner_image': default_banner });
    });

    if (sections.length) {

      sections.forEach(element => {
        this.rest._get(apiHelp + '/' + element).subscribe(async (response) => {

          var banner: any = response.body;

          console.log('Banner for section ' + element, banner.data.banner);

          if (banner.data.banner) {
            banner.data.banner.forEach((b) => {
              this.banners.push(b);
            });
            this.outData.emit({ banners: this.banners });
            console.log('Banners: ', this.banners);
          }
        }, (error) => {
          console.log('Error getting banner: ', error);
        });
      });
    }
  }

  onResize(event) {
    try {
      if (event.target.innerWidth < 800) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      this.outData.emit({ isMobile: this.isMobile });
    } catch (error) {
      console.log(error);
    }
  }

  findBanner(section, mobile = false) {
    let banner = this.banners.find(e => (e.banner_section == section && e.is_mobile == mobile));
    return banner;
  }

  clickBanner(banner) {
    console.log(banner);
    if (banner && (banner.banner_url && banner.banner_target)) {
      window.open(banner.banner_url, banner.banner_target);
    }
  }

  closeTop() {
    this.search_section = null;
    this.outData.emit({ home: true, search: null });
    this.outData.emit({ blur: true });
    this.variableNavBarService.updateVarOnSearchBar(false);
    //this.outData.emit({ blur: true });
    //this.outData.emit({ focus: false });
    //this.backToHome();
    //this.clearFilters();
  }

  clearFilters() {
    this.filter_parameters = [];
  }

  redirectOldVersion(link) {
    window.open(link, '_self');
  }

  async getResults() {
    const loader = await this.loadingCtrl.create({});

    this.rest._get( typeof this.query === 'undefined' ? 'general/search/' + this.searchTop + '/' + this.offsetcount : 'general/search/' + this.searchTop + '/' + this.offsetcount + this.query).subscribe(async (response) => {
      const auxResp: any = response.body;

      // Only modify the arrays if there are results

      if (auxResp.hits && auxResp.hits.length > 0) {
        auxResp.hits = auxResp.hits.sort((a, b) => (a.views < b.views ? -1 : 1));
        auxResp.hits = auxResp.hits.map(obj => ({ ...obj, url: location.origin + ((obj.type == "Disc" && !obj.subType) ? '/creditos/' : (obj.type == "Sound-Market" && !obj.subType) ? '/servicios/sound/' : (obj.type == "Lesson" || obj.subType == "Lesson") ? '/servicios/lesson/' : obj.type == "Profile" ? '/perfiles/' : (obj.type == "Search" && obj.subtype == "ServiceSearch") ? '/busquedas-publicadas/sound/' : (obj.type == "Search" && obj.subtype == "LessonSearch") ? '/busquedas-publicadas/LessonSearch/' : '/cursos/') + obj.id, new: this.monthDiff(new Date(obj.date), new Date()) }));

        //Filter if section is provided
        if (this.searchSection && this.searchSection.length > 0){
          auxResp.hits = auxResp.hits.filter(hit =>  hit.type.toLowerCase() == this.searchSection.toLowerCase());
          // auxResp.types = auxResp.types.filter(type =>  type.toLowerCase() == this.searchSection.toLowerCase());
        }

        if (this.searchCategory && this.searchCategory.length > 0){
          auxResp.hits = auxResp.hits.filter(hit =>  hit.principal_category.toLowerCase() == this.searchCategory.toLowerCase());
          // auxResp.types = auxResp.types.filter(type =>  type.toLowerCase() == this.searchSection.toLowerCase());
        }

        this.algolia = auxResp;
        const xalgolia = auxResp;
        if (auxResp) {
          if (xalgolia.hits.length < 1) {
            this.isAllData = true;
          } else {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < xalgolia.hits.length; i++) {
              this.algoliaHits.push(xalgolia.hits[i]);
            }
            if (xalgolia.hits.length < 20) {
              this.isAllData = true;
            }
          }
        }

        if (this.searchSection) {
          this.filterSearch(null, this.searchSection);
        }
      }
      // console.log("algolia", auxResp);
      // console.log("algoliaHits", this.algoliaHits);
      loader.dismiss();
    }, (error) => {
      loader.dismiss();
      console.error('error: ', error);
    });
  }

  loadData(event) {
    console.log('EVENT: ', event);
    this.offsetcount = this.offsetcount + 1;
    setTimeout(() => {
      console.log('Done' + this.offsetcount);
      if (this.platform.is('ios')) {
        event.preventDefault();
      }
      if (this.query) {
        this.search_with_query(this.query);
      } else {
        this.getResults()
      }
      // getNews();
      event.target.complete();

      if (this.isAllData) {
        // HIDE ONLY NOT DISABLED FOR ALL
        // event.target.disabled = true;
        console.log('this.isAllData: ', this.isAllData);
      }
    }, 500);
  }

  onScroll() {
    console.log("scrolled!!");
    this.offsetcount = this.offsetcount + 1;
    setTimeout(() => {
      console.log('Done' + this.offsetcount);
      /*if (this.platform.is('ios')) {
        event.preventDefault();
      }*/
      if (this.query) {
        this.search_with_query(this.query);
      } else {
        this.getResults();
      }
      // getNews();=

      if (this.isAllData) {
        // HIDE ONLY NOT DISABLED FOR ALL
        // event.target.disabled = true;
        console.log('this.isAllData: ', this.isAllData);
      }
    }, 500);
  }


  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    return months <= 0;
  }



  filteChange(ev) {
    // console.log(ev);
    if (this.algolia && this.algolia.hits && this.algolia.hits.length){
      if (ev.detail.value === 'menor') {
        this.algolia.hits = this.algolia.hits.sort(this.compare_by_price_min);
      } else {
        this.algolia.hits = this.algolia.hits.sort(this.compare_by_price_max);
      }
    }
  }

  compare_by_price_min(a, b) {
    if (a.price && b.price) {
      if (+a.price < +b.price) {
        return -1;
      }
      if (+a.price > +b.price) {
        return 1;
      }
      return 0;
    } else {
      if (a.price && !b.price) {
        return 1
      } else {
        if (!a.price && b.price) {
          return 1;
        }
        else {
          return 1;
        }
      }
    }
  }


  compare_by_price_max(a, b) {
    if (a.price && b.price) {
      if (+a.price < +b.price) {
        return 1;
      }
      if (+a.price > +b.price) {
        return -1;
      }
      return 0;
    } else {
      if (a.price && !b.price) {
        return -1
      } else {
        if (!a.price && b.price) {
          return 1;
        }
        else {
          return 0;
        }
      }
    }
  }



  mapping_type_or_normal(id, value) {
    if (id == 'type') {
      return this.map_db_to_page[value];
    }
    else { return value; }
  }

  async searchServices(body) {
    const loader = await this.loadingCtrl.create({});

    var filters = '?filters=';
    var keys = Object.keys(body);
    keys.forEach(function (value) {
      if (body[value] && value != 'query' && value != 'isSearch') {
        if (Array.isArray(body[value])) {
          body[value].forEach(function (element) {
            filters = filters + value + ':' + element.name + '.';
          });
        } else {
          filters = filters + value + ':' + body[value] + '.';
        }


      }
    });
    filters = filters.slice(0, filters.length - 1)
    this.searchTop = body.query;
    this.showOverSearch = true;
    this.rest._get('general/search/searchServices/' + body.query + filters).subscribe(async (response) => {
      const auxResp: any = response.body;
      auxResp.hits = auxResp.hits.sort((a, b) => (a.views < b.views ? -1 : 1));
      auxResp.hits = auxResp.hits.map(obj => ({ ...obj, new: this.monthDiff(new Date(obj.date), new Date()) }));
      this.algolia = auxResp;
      // console.log("algolia", auxResp);
      loader.dismiss();
    }, (error) => {
      console.error('error: ', error);
      loader.dismiss();
    });
  }

   checkSearch(itx) {
    const email = new RegExp("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$");
    if (this.util.token && this.util.user.msisdn === null
      || (this.util.token && this.util.user.msisdn !== null && (email.test(this.util.user.email) === true))) {
      const body = 'Mensaje enviado a partir de la busqueda: ' + '\n' + itx.title + '\n' + itx.url;

      this.rest._get('accounts/user-information/' + itx.ownerId).subscribe(response => {
        this.util.setChatTargetUser(response.body);
        let chat_link = location.origin + '/mensajes/usuario/' + itx.ownerId + '/' + encodeURIComponent(body);

        window.open(chat_link, '_self');
      });
    }else if(this.util.token && this.util.user.msisdn !== null && (email.test(this.util.user.email) === false)){
      this.showModalRegisterTelco();
    }
    else {
      this.showModalRegister();
    }
  }

  showModalRegisterTelco() {
    this.modalShowRegisterTelco = !this.modalShowRegisterTelco;
  }

  showModalRegister() {
    this.modalShowRegister = !this.modalShowRegister;
  }

  gotoLogin() {
    this.navCtrl.navigateForward('/login');
    this.modalShowRegister = false;
  }

  gotoRegister() {
    this.navCtrl.navigateForward('/registro');
  }


  filterSearch(ev, section = null) {

    var element_to_search = section ? section : ev.target.innerHTML.trim();
    console.log("Filtro: ", element_to_search);

    this.appendTeachers = false;

    if (ev && ev.target.classList[1] == "type") {
      if (element_to_search == 'Services' || element_to_search == 'Servicios') {
        element_to_search = 'Sound-Market';
        this.appendTeachers = true;
      }
      if (element_to_search == 'Teachers' || element_to_search == 'Profesores') {
        element_to_search = 'Lesson';
      }
      if (element_to_search == 'Published searches' || element_to_search == 'Búsquedas publicadas') {
        element_to_search = 'Search';
      }
      if (element_to_search == 'course' || element_to_search == 'Cursos On Demand' || element_to_search == 'OnDemand Courses'){
        element_to_search = 'Course';
      }
      if (element_to_search == 'Profile' || element_to_search == 'Usuarios' || element_to_search == 'Users'){
        element_to_search = 'Profile';
      }
      if (element_to_search == 'Producciones' || element_to_search == 'Credits'){
        element_to_search = 'Disc';
      }
    } else {
      if (section == 'sound-market'){
        element_to_search = 'Sound-Market';
        this.appendTeachers = true;
      }
      if (section == 'lesson'){
        element_to_search = 'Lesson';
      }
      if (section == 'course' || element_to_search == 'Cursos On Demand' || element_to_search == 'OnDemand Courses'){
        element_to_search = 'Course';
      }
      if (section == 'search'){
        element_to_search = 'Search';
      }
      if (section == 'Producciones' || section == 'Credits'){
        element_to_search = 'Disc';
      }
    }

    console.log('Filtro Map Page:', element_to_search);

    //var amount_id = (this.filter_parameters.filter(filter => filter.id === (section ? "type" : ev.target.classList[1]))).length;
    var amount_value = (this.filter_parameters.filter(filter => filter.value === element_to_search)).length;

    if (amount_value == 0) {
      this.filter_parameters = [...this.filter_parameters, { "id": section ? "type" : ev.target.classList[1], "value": element_to_search }];
      if (this.appendTeachers) {
        amount_value = (this.filter_parameters.filter(filter => filter.value === 'Lesson')).length;
        if (amount_value == 0) {
          this.filter_parameters = [...this.filter_parameters, { "id": "type", "value": "Lesson" }];
        }
      }
      var query = this.generate_query();
      this.algoliaHits = [];
      this.offsetcount = 0;
      this.isAllData = false;
      this.search_with_query(query, section);
    }
  }

  delete_filter(ev, value) {
    this.filter_parameters = this.filter_parameters.filter(filter => filter.value != value);
    var query = this.generate_query();
    this.algoliaHits = [];
    this.offsetcount = 0;
    this.isAllData = false;
    this.search_with_query(query);
  }

  clearAllFilters(){
    this.outData.emit({ clearFilter: true });
  }

  backToHome(){
    this.outData.emit({ goHome: true });
  }

  onFocus(ev) {
    this.outData.emit({ focus: true });
  }

  generate_query() {
    let query = '?';
    let secciones = this.filter_parameters.filter(filter => filter.id === 'type').reduce((acc, cur) => acc + cur.value + '.', '');
    let categories = this.filter_parameters.filter(filter => filter.id === 'category').reduce((acc, cur) => acc + cur.value + '.', '');
    //this.filter_parameters.forEach(filter => query = query + filter.id + '=' + filter.value + '&');

    if (secciones.length > 0) {
      secciones = secciones.substring(0, secciones.length - 1);
      query = query + 'type=' + secciones;
    }

    if (categories.length > 0) {
      categories = categories.substring(0, categories.length - 1);
      query = query + '&category=' + categories;
    }

    return query;
  }

  async search_with_query(query, section = null) {
    this.query = query;

    const loader = await this.loadingCtrl.create({});

    this.rest._get('general/search/' + this.searchTop + '/' + this.offsetcount + query).subscribe(async (response) => {
      const auxResp: any = response.body;

      if (auxResp && auxResp.hits.length > 0) {

        auxResp.hits = auxResp.hits.sort((a, b) => (a.views < b.views ? -1 : 1));
        // auxResp.hits = auxResp.hits.map(obj => ({ ...obj, new: this.monthDiff(new Date(obj.date), new Date()) }));
        auxResp.hits = auxResp.hits.map(obj => ({ ...obj, url: location.origin + ((obj.type == "Disc" && !obj.subType) ? '/creditos/' : (obj.type == "Sound-Market" && !obj.subType) ? '/servicios/sound/' : (obj.type == "Lesson" || obj.subType == "Lesson") ? '/servicios/lesson/' : obj.type == "Profile" ? '/perfiles/' : (obj.type == "Search" && obj.subtype == "ServiceSearch") ? '/busquedas-publicadas/sound/' : (obj.type == "Search" && obj.subtype == "LessonSearch") ? '/busquedas-publicadas/LessonSearch/' : '/cursos/') + obj.id, new: this.monthDiff(new Date(obj.date), new Date()) }));
        // //Filter if section is provided
        // if (this.searchSection && this.searchSection.length > 0){
        //   auxResp.hits = auxResp.hits.filter(hit =>  hit.type.toLowerCase() == this.searchSection.toLowerCase());
        // }

        this.algolia = auxResp;
        const xalgolia = auxResp;
        if (xalgolia.hits.length < 1) {
          this.isAllData = true;
        } else {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < xalgolia.hits.length; i++) {
            this.algoliaHits.push(xalgolia.hits[i]);
          }
          if (xalgolia.hits.length < 20) {
            this.isAllData = true;
          }
        }
      }
      // console.log("algolia", auxResp);
      loader.dismiss();
    }, (error) => {
      console.error('error: ', error);
    });
  }

  onSwiper(ev) {

  }

  onSlideChange() {
  }


  onBlur() {
    this.outData.emit({ blur: true });
  }

  hideKeyboard() {

  }
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event:
    KeyboardEvent) {
    this.outData.emit({ search: '' });
    this.searchTop = '';
  }

  goToNewServices() {
    //this.closeTop();
    //this.navCtrl.navigateRoot('/home');
    this.outData.emit({ openGetProposal: true });
  }

  presentPopoverFilter(e: Event) {
    this.popoverFilter.event = e;
    this.isOpenFilter = true;
  }
}
