import { LangService } from './lang.service';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  dataParams: any;
  dataFCM: any;
  textWhats: any;
  repoPhone: string;
  downloadProgress: number;
  token: any;
  user: any;
  chatTargetUser: any;
  firebase: any;
  db: any;
  languaje: any = 'es';

  constructor(
    public platform: Platform,
    public fileOpener: FileOpener,
    public langService: LangService,
    public translate: TranslateService,
    public http: HttpClient) {
      this.langService.getLanguajeStorage().then((val => {
        if (val) {

          console.log('>>>XXX1-home', val);
          this.languaje = val
          console.log('>>>XXX3');
        }
      })).catch((err) => {
        console.log('>>>XXX5');
      });

  }

  setToken(token: any) {
    this.token = token;
  }

  setUser(user: any) {
    console.log('Set local user', user);
    this.user = user;
  }

  setChatTargetUser(user: any) {
    console.log('Set target chat user', user);
    localStorage.setItem('chatTargetUser', JSON.stringify(user));
    this.chatTargetUser = user;
  }

  getChatTargetUser() {
    return JSON.parse(localStorage.getItem('chatTargetUser'));
  }

  setFirebase(firebase: any, db: any) {
    this.firebase = firebase;
    this.db = db;
    console.log('Firebase: ', this.firebase);
  }

  setDataAux(val) {
    this.dataParams = val;
  }

  setDataFCM(val) {
    this.dataFCM = val;
  }
  /*
    async download(url: any, type, end) {
      let path = null;
      if (this.platform.is('ios')) {
        path = this.file.dataDirectory;
      } else {
        path = this.file.externalRootDirectory + '/Download/';
      }
      const date = new Date();
      const dateNow = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
      this.dirFileName = type + dateNow + '.' + end;
      const transfer = this.transfer.create();
      const loader = await this.loadingCtrl.create({
        message: 'Descargando...'
      });
      loader.present();
      transfer.download(url, path + this.dirFileName).then((entry) => {
        const ruta = entry.toURL();
        const auxb = Math.floor(Math.random() * 110);
        this.presentToast('Descarga completa!', 'success', 2200);
        // this.document.viewDocument(ruta, 'application/pdf', {});
        loader.dismiss();
        try {
          this.helpOpen(end, ruta);
        } catch (error) {
          console.error(error);
        }

      }).catch((err) => {
        loader.dismiss();
        if (this.platform.is('android') && !this.isAndroidPermission) {
          this.showAlert();
        } else {
          console.log('erxxxx');
          console.log(JSON.stringify(err));

          const auxError: any = err;
          if (auxError.code === '1') {
            this.presentAlert('Fallo por archivo no encontrado');
          } else if (auxError.code === '2') {
            this.presentAlert('Fallo por URL no válida');
          } else if (auxError.code === '3') {
            this.presentAlert('Fallo por conexión');
          } else if (auxError.code === '4') {
            this.presentAlert('Fallo por abortar proceso');
          } else if (auxError.code === '5') {
            this.presentAlert('Fallo por no modficado');
          } else {
            this.presentAlert('Fallo cod_101, intente mas tarde');
          }
        }
      });
    }*/

  private helpOpen(ruta: any, end) {
    console.log('here', end, ruta);

    if (end === 'pdf') {
      this.fileOpener.open(ruta, 'application/pdf')
        .then(() => console.log('File is opened'))
        .catch(e => console.log('Error opening file', e));
    }
    if (end === 'mp4') {
      this.fileOpener.open(ruta, 'video/mp4');
    }
    if (end === 'png' || end === 'jpg' || end === 'jpeg') {
      this.fileOpener.open(ruta, 'image/' + end);
    }
    if (end === 'ppt') {
      this.fileOpener.open(ruta, 'application/vnd.ms-powerpoint');
    }
    if (end === 'pptx') {
      this.fileOpener.open(ruta, 'application/vnd.openxmlformats-officedocument.presentationml.presentation');
    }
    if (end === 'xls') {
      this.fileOpener.open(ruta, 'application/vnd.ms-excel');
    }
    if (end === 'xlsx') {
      this.fileOpener.open(ruta, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }
  }

  /*
    checkDirec(value) {
      let path = null;
      if (this.platform.is('ios')) {
        path = this.file.documentsDirectory;
      } else {
        path = this.file.externalRootDirectory;
      }
      return this.file.checkDir(path, value).then((val) => {
        if (val) {
          console.log('checkDirec' + JSON.stringify(val));
          return val;
        }
      }).catch((err) => {
        console.log('err' + JSON.stringify(err));
        alert('Fallo del sistema cod_102');
        return false;
      });
    }*/

  downloadByURL(url, end, tt) {
    this.checkP();
    this.http.get(url, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events',
    }).subscribe(async (event) => {
      if (event.type === HttpEventType.DownloadProgress) {
        this.downloadProgress = Math.round(100 * event.loaded / event.total);
      } else if (event.type == HttpEventType.Response) {
        this.downloadProgress = 0;

        const name = 'testxx';
        const base64 = await this.convertBlobToBase64(event.body) as string;

        const dat = await Filesystem.writeFile({
          path: name + tt + '.pdf',
          data: base64,
          directory: Directory.Documents
        });
        if (dat) {
          console.log('completd', JSON.stringify(dat));
          const path = dat.uri;

          this.helpOpen(path, end);
        }

      }
    })
  }

  checkP() {
    Filesystem.requestPermissions().then(() => {
      console.log('success requestPermissions');
    }).catch((err) => {
      console.log('erroe requestPermissions', err);
    });
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result)
    };
    reader.readAsDataURL(blob);
  });
  /*
    createDirec(value) {
      let path = null;
      if (this.platform.is('ios')) {
        path = this.file.documentsDirectory;
      } else {
        path = this.file.externalRootDirectory;
      }
      return this.file.createDir(path, value, false).then((val) => {
        if (val) {
          console.log('createDirec' + JSON.stringify(val));
          return val;
        }
      }).catch((err) => {
        alert('Fallo del sistema cod_103');
        return false;
      });
    }*/

  getFixed(amount, currency) {
    // amount = Number(amount.toFixed(2));
    // console.log(numberFormat1.format(amount));

    if (amount || typeof amount === 'string') {
      amount = Number(amount);
    }
    if (!amount || typeof amount !== 'number') {
      return '';
    }
    switch (currency) {
      case 'ARS':
        var numberFormat1 = new Intl.NumberFormat('en-AR', { style: 'currency', currency: 'ARS', maximumFractionDigits: 2 });
        break;
      case 'USD':
        var numberFormat1 = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });
        break;
    }
    return numberFormat1.format(amount);
  }

  arrayToString(data: Array<any>, defaultValue = '', getValue = function (element) { return element; }, unionChar = ', ', finalUnionChar = ', ') {

    let result = '';
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        let language = this.languaje;
        this.translate.use(language);
        let valor = getValue(element);
        // console.log(valor);
        valor = this.translate.instant(valor);
        if (data.length > 1 && index > 0) {
          if ((index + 1) == data.length) {
            result += finalUnionChar + valor;
          } else {
            result += unionChar + valor;
          }

        } else {
          result += valor;
        }
      }
    } else {
      result = defaultValue;
    }
    return result;
  }

  getStars(rate: number) {
    const stars = [];
    let i: number;

    for (i = 0; i <= 4; i++) {
      if (i < Math.floor(rate)) {
        stars[i] = true;
      } else {
        if ((rate - Math.floor(rate)) < 1 && (rate - Math.floor(rate)) > 0 && i < rate) {
          stars[i] = 'half';
        } else {
          stars[i] = false;
        }
      }
    }

    return stars;
  }



  processDescription = (element) => { return element.description; };

  processName = (element) => { return element.name; };

}
